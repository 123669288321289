'use strict';

function applyHeaderBannerPromo() {
    if ($('.header-banner').find('[data-active-promo-code]').length) {
        var codeHolder = $('.header-banner').find('[data-active-promo-code]');
        var ccodeURL = codeHolder.attr('data-ccode-url');
        var promoCode = codeHolder.attr('data-active-promo-code');
        var $xhr;

        if (promoCode && promoCode !== '') {
            // Cancel previous request
            $xhr && $xhr.abort && $xhr.abort();

            $xhr = $.ajax({
                url: ccodeURL + '?ccode=' + promoCode,
                type: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (data.error) console.log('promocode not applied');
                }
            });
        }
    }
}

module.exports = {
    applyHeaderBannerPromo: applyHeaderBannerPromo
}