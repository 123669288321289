'use strict';

var wishlist = require('core/wishlist/wishlist');

var productListEnhancementsLanding =  require('core/productListEnhancements/landing').init;
var productListEnhancementsDetail =  require('core/productListEnhancements/detail').init;
var productListEnhancementsShowLists =  require('core/productListEnhancements/modalShowLists').init;
var productListEnhancementsToast = require('core/productListEnhancements/toast').init;
//New Changed Files:
var productListEnhancementsToggleProduct =  require('../productListEnhancements/toggleProduct').init;
var saveForLaterListActions = require('../productListEnhancements/saveForLater').init;

wishlist.methods = {
    ...(wishlist.methods || {}),
    ... {
        productListEnhancementsLanding,
        productListEnhancementsDetail,
        productListEnhancementsShowLists,
        productListEnhancementsToast,
        productListEnhancementsToggleProduct,
        saveForLaterListActions
    }
};

wishlist.init = function() {
    module.exports.cartUpdate();
    module.exports.toggleWishlist();
    module.exports.updateWishlistLinkData();
    module.exports.copyWishlistLink();
    module.exports.updateQuickView();
    module.exports.viewProductViaEdit();
    module.exports.focusEditWishlistProductModal();
    module.exports.onClosingEditWishlistProductModal();
    module.exports.trapEditWishlistProductModalFocus();
    module.exports.addToCartFromWishlist();
    module.exports.toggleWishlistStatus();
    module.exports.toggleWishlistItemStatus();
    module.exports.removeFromWishlist();
    module.exports.moreWLItems();
    module.exports.methods.productListEnhancementsLanding();
    module.exports.methods.productListEnhancementsDetail();
    module.exports.methods.productListEnhancementsShowLists();
    module.exports.methods.productListEnhancementsToggleProduct();
    module.exports.methods.saveForLaterListActions();
    module.exports.methods.productListEnhancementsToast();
}

module.exports = wishlist;
