'use strict';

var base = require('core/components/menu');
var SiteConstants = require('constants/SiteConstants');
var headerUtils = require('../utilities/headerUtils');
var keyboardAccessibility = require('base/components/keyboardAccessibility');

// Modification from base to NOT hide banner when modal dismiss is clicked
base.headerBanner = function() {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close-button .close:not([data-dismiss="modal"])').on('click', () => {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }
};

base.kbAccessibility = function () {
    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .header-locale-container > div, .header-account-container > div, .header-support-container > div, .minicart-link',
        {
            40: function(menuItem) { // down
                if (menuItem.is('.minicart-link') || (menuItem.find('.minicart') && menuItem?.prevObject?.is('.minicart'))) { // minicart
                    $('.minicart').trigger('minicart:keyboard:show');
                    return;
                }
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    $(this).attr('aria-expanded', 'true');
                } else if (menuItem.is('.header-account-container, .header-support-container, .header-locale-container')) { // account/support
                    menuItem.find('.custom-dropdown').addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'true');
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus(); // set focus to the first menuitem
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function(menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.is('.header-account-container, .header-support-container, .header-locale-container')) { // account/support
                    menuItem.find('.custom-dropdown').addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'true');
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function(menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                } else if (menuItem.is('.minicart-link') || (menuItem?.prevObject && (menuItem.prevObject.is('.minicart') || menuItem.prevObject.is('.minicart-link')))) { // minicart
                    $('.minicart').trigger('minicart:keyboard:hide');
                } else if (menuItem.is('.header-account-container, .header-support-container, .header-locale-container')) { // account/support
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children().first().focus(); // set the focus to the last menuItem
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function(menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else if (menuItem.is('.minicart-link') || (menuItem?.prevObject && (menuItem.prevObject.is('.minicart') || menuItem.prevObject.is('.minicart-link')))) { // minicart
                    $('.minicart').trigger('minicart:keyboard:hide');
                } else if (menuItem.is('.header-account-container, .header-support-container, .header-locale-container')) { // account/support
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children().first().focus().attr('aria-expanded', 'false');
                }
            },
            27: function(menuItem) { // escape
                if (menuItem.is('.minicart-link') || (menuItem?.prevObject && (menuItem.prevObject.is('.minicart') || menuItem.prevObject.is('.minicart-link')))) { // minicart
                    $('.minicart').trigger('minicart:keyboard:hide');
                } else if (menuItem.is('.header-account-container, .header-support-container, .header-locale-container')) { // account/support
                    menuItem.find('.custom-dropdown').removeClass('show').children('.dropdown-menu').removeClass('show');
                    menuItem.find('.dropdown-toggle').attr('aria-expanded', 'false');
                } else {
                    var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('.show');
                    parentMenu.children('.show').removeClass('show');
                    parentMenu.removeClass('show').children('.nav-link')
                        .attr('aria-expanded', 'false');
                    parentMenu.children().first().focus();
                }
            }
        },
        function () {
            return $(this).parent();
        }
    );
}

// Modification from base to change mobile menu top position and sticky-header/menu disappearing
base.toggleDrawer = function(status) {
    var $header = $('header');
    var $headerNav = $header.find('.header-nav');
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');

    if (status === 'open') {
        var stickyHeader = $('.sticky-header').length;
        var navPageScrollLocation = stickyHeader ? Math.round($headerNav.offset().top) : 0;
        $('body')
            .css('top', '-' + navPageScrollLocation + 'px')
            .addClass('mobile-menu-in');
        if (stickyHeader) $('html').addClass('sticky-header-lock');
        $('html').addClass('lock-scroll');
        $header
        .siblings().attr('aria-hidden', 'true');
        $navbar
        .find('.nav-link').first().focus();
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed);
    } else {
        var pageScrollLocation = Math.abs(parseInt($('body').css('top')));
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $('body').css('top', '');
            $('html')
                .removeClass('lock-scroll sticky-header-lock')
                .scrollTop(pageScrollLocation);
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

// Modification from base to handle custom top category back button
base.dropdownMenu = function() {
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events
        if (window.isMobile()) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');
            var $li = $('<li class="dropdown-item top-category back" role="button"></li>'); // Just added back class
            var $closeMenu = $('<li class="nav-menu"></li>');
            var link = $dropdownLink.clone().removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .removeAttr('href')
                .attr('aria-haspopup', 'false');
            $li.append(link);
            $closeMenu.append($('.close-menu').first().clone());
            $dropdown.children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false')
                .find('a').removeAttr('tabindex', '-1');
            $dropdown.addClass('show');
            $dropdownLink.attr('aria-expanded', 'true');
            $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
            $(link).focus();
            $('.dropdown-menu').scrollTop(0);
        }
    });

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });
     // Desktop - close menu on mouseleave
     $dropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });
};


//SAS change: pulling this in since it wasn't calling module.exports.toggledrawer => changing that function didn't update functionality
base.navbarToggler = function() {
    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            base.toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            base.toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => base.toggleDrawer('close'));
};

module.exports = base;
