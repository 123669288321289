'use strict';
var base = require('client_core/components/buttons');

function addBtnLoadingState() {
    var $clickedButton;

    $('.btn').on('mousedown', function (e) {
        $clickedButton = $(this);
    });

    function removeLoadingState() {
        if ($clickedButton) {
            $clickedButton.removeClass('loading-logo');
            $clickedButton = null;
        }
    }

    $(document).ajaxStart(function() {
        if ($clickedButton) {
            $clickedButton.addClass('loading-logo');
        }
    });

    $(document).ajaxStop(removeLoadingState);
    $(document).ajaxError(removeLoadingState);
}

function clearBtnActiveState() {
    $('.btn:not([data-toggle="popover"])').on('click', function (e) {
        $(this).blur();
    });
}

module.exports = {
    addBtnLoadingState: addBtnLoadingState,
    clearBtnActiveState: clearBtnActiveState
};
