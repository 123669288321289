
'use strict';

var dataLayerHelper = require('../utilities/dataLayerHelper');

const Trigger = 'pushTracking';
const Wishlist = 'wishlist';
const Refinement = 'searchRefinement';
const EmailSignUp = 'emailSignUp';

var elementIsInView = new IntersectionObserver(onIntersection, {
    root: null,   //viewport
    threshold: .5 //wait till at least half the element is in-view
});

function onIntersection(entries, options){
    entries.forEach(entry => entry.isIntersecting ? buttonCheck(entry.target) : '')
};

var promosInDataLayer = [];

function buttonCheck(link, clicked) {
    var $link =Array.isArray(link) ? link[0] : link;
    if ($link) {
        //try to look for any "title" text to apply a label if not grab an aria label description or any relevant info for tracking
        var closestTitle = $($link).closest('.experience-component').find('[class*="title"]').eq(0).text().trim() || '';
        var closestAriaLabel = $($link).attr('aria-label') || $($link).closest('*[aria-label]').attr('aria-label') || '';
        var linkID = $($link).attr('id') || '';
        var closestPageDesignerID = $($link).closest('.page-designer:not(.storepage').attr('id') || '';
        var closestComponentID = $($link).closest('[data-component-id]').attr('data-component-id') || '';
            closestComponentID = closestComponentID ? 'ComponentID: ' + closestComponentID : '';
        var promoLabel = closestTitle || closestAriaLabel || closestAriaLabel || linkID || closestPageDesignerID || closestComponentID;
        var event = clicked ? 'select_promotion' : 'view_promotion';
        if ($link.href !== window.location.href) {
            if (promoLabel && !promosInDataLayer.includes(event+':'+promoLabel)) {
                promosInDataLayer.push(event+':'+promoLabel);
                dataLayerHelper.PushToDataLayer({
                    event: event,
                    promotion_name: promoLabel.toString().split('.')[0]
                });
            }
            //unobserve so it only fires once per element per pageview
            elementIsInView.unobserve($link);
        }
    }
}

function addListenersForTracking () {
    $('.product-list-enhancements-toggle-product').on('click', function(data) {
        if (data.currentTarget.dataset.inproductlistenhancements === "false") {
            data.target = data.currentTarget;
            $('body').trigger( Trigger, [Wishlist, data] );
        }
    });

    $('.search-results').on('click', '.refinement .values button, .filter-bar .filter-value .btn', function (data) {
        $('body').trigger( Trigger, [Refinement, data] );
    });

    $('body').on('footer:signup:success emarsys:emailSubscribe', function(e, data) {
        $('body').trigger( Trigger, [EmailSignUp, data] );
    });

    //Add Homepage Link listeners
    if ($('.page[data-action="Home-Show"]').length) {
        var $componentSelectors = $('.experience-component, .header-banner, #maincontent');
        $componentSelectors.on('click', '*[href]', function () {
            buttonCheck(this, true);
        });
        $componentSelectors.each( (i, elem) => elementIsInView.observe(elem) );
    }
};

/**
 * SAS: adding datalayerPush after event
 * @param {event} e - data returned from the trigger
 */
function pushTrackingEvent () {

    $('body').on( Trigger , function (e, trackingName, trackingData) {
        var pushObject;

        if (trackingData && (trackingData.target || e.target)) {
            var target = trackingData.target || e.target;

            if (trackingName == Wishlist) {
                // check if the toast event is showing from adding an item to wishlist
                if (target && target.dataset.wishlistpid) {
                    var pid = target.dataset.wishlistpid;
                    //Grab the item id from the product or the gtm data
                    var itemId = $('[data-wishlistpid="' + pid + '"]').closest('.product')?.data()?.pid || $('.product-detail').find('.add-to-cart')?.data()?.gtmga4data?.item_id;

                    pushObject = {
                        'event': 'add_to_wishlist',
                        'item_id': itemId || pid,
                        'item_variant': pid
                    };
                }
            };

            if (trackingName == Refinement) {
                var filterData = $(target).closest('li').data();
                var remove = filterData.selected;
                var category = filterData.category;
                var categoryID = filterData.id || $(target).closest('.refinement').find('button span').html();
                var value = filterData.displayValue;

                if (value) {
                    pushObject = {
                        'event': (remove ? 'remove' : 'add') + '_refinement',
                        'refinement_category_id': categoryID,
                        'refinement_category': category,
                        'refinement_value': value
                    };
                }
            };

            if (trackingName == EmailSignUp) {
                pushObject = { 'event': 'email_signup' };
                if (trackingData && trackingData.action) pushObject.location = trackingData.action;
            }

            if (pushObject) dataLayerHelper.PushToDataLayer(pushObject);
        }
    });
};

module.exports = {
    pushTrackingEvent: pushTrackingEvent,
    addListenersForTracking: addListenersForTracking
};
