'use strict';

var base = require('core/components/toolTip');

module.exports = function () {
    $('[data-toggle="tooltip"], .info-icon')
        .on('focusin', function() {
            $(this).trigger('mouseenter');
        })
        .on('focusout', function() {
            $(this).trigger('mouseleave');
        });

    return base.apply(this, arguments);
}