'use strict';

var core = require('core/product/tile');
var swatchSwitchCoreMethod = core.methods.swatchSwitch;

function swatchSwitch(el) {
    swatchSwitchCoreMethod(el);

    //Add Price change per swatch
    var $swatch = el;
    var $tileParent = $swatch.parents('.product-tile');
    var $price = $tileParent.find('.price');
    var $promotionContainer = $tileParent.find('.promotion-container');

    if ($swatch.data('hover-price') && $swatch.data('hover-price') !='') {
        $price.replaceWith($swatch.data('hover-price'));
    }
    // If the data promotion-html value is null/undefined replace with empty string
    $promotionContainer.html($swatch.data('promotion-html') ?? '');
}

core.mouseEnter = function() {
    $('body')
        .off('mouseenter', '.product-tile .swatch')
        .on('mouseenter', '.product-tile .color-swatches .swatch', function(e) {
            var $swatch = $(this);
            core.methods.swatchSwitch($swatch);
        });
}

core.swatchBundleBuilderSwap = function() {
    $('body').on('mouseenter', '.product-bundle-builder-tile .swatch', function () {
        var selectedPid = $(this).data().wishlistpid;
        if (selectedPid) {
            var $container = $(this).closest('.product-bundle-builder-tile');
            var $addToCartBtn = $container.find('button[class*="add-to-cart"]');
            $addToCartBtn.attr('data-pid', parseInt(selectedPid)).data('pid', parseInt(selectedPid));
        }
    });
}

core.bundleBuilderUpdate = function () {
    $('.slider').on('click', '.single-variant-bundle-builder-add-to-cart', function () {
        var $this = $(this);

        $('body').on('product:afterAddToCart', () => {
            $this.find('.product-tile-bundle-builder-added-text').removeClass('d-none');

            var $bundleStepContainer = $('[class*=bundle-step-container-]').first(); // Select the first matching element
            var currentBundleStepContainer = $bundleStepContainer.attr('class').match(/bundle-step-container-\d+/);

            if (currentBundleStepContainer) {
                var currentIndexNumberAsString = currentBundleStepContainer[0].match(/\d+/)[0];
                var parseStringedIndexNumberToInteger = parseInt(currentIndexNumberAsString, 10);
                var nextSliderNumber = parseStringedIndexNumberToInteger + 1;
                var bundleStepContainerClassName = '.bundle-step-container-' + nextSliderNumber;

                var $nextSlider = $(bundleStepContainerClassName);
                if ($nextSlider.length) {
                    $('html, body').animate({
                        scrollTop: $nextSlider.offset().top
                    }, 'smooth');
                }
            }

            $this.find('.product-tile-bundle-builder-select-text').addClass('d-none');
        });
    });
}

core.bundleBuilderMinicartUpdate = function () {
    $('#removeProductModal').on('click', '.cart-delete-confirmation-btn', function () {
        var productID = $(this).data('pid');

        $('.single-variant-quick-add-to-cart').each(function () {
            var $this = $(this);
            if ($this.data('pid') === productID) {
                $this.find('.product-tile-bundle-builder-select-text').removeClass('d-none');
                $this.find('.product-tile-bundle-builder-added-text').addClass('d-none');
            }
        });
    });
}

core.methods.swatchSwitch = swatchSwitch;

module.exports = core;
