'use strict';

var base = require('core/productListEnhancements/toggleProduct');
var toast = require('core/components/toast');

function confirmDelete(itemToMove) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $modalBody = $('.delete-confirmation-body');

    $deleteConfirmBtn.data('pid', itemToMove.productID);
    $deleteConfirmBtn.data('action', itemToMove.actionUrl);
    $deleteConfirmBtn.data('uuid', itemToMove.uuid);
    $('#removeProductModal').find('.modal-title').html(itemToMove.message)

    $modalBody.empty().append(itemToMove.modalBodyDelete).append('<p class="product-to-remove>'+ itemToMove.productName +'</p>');
}

/**
 * update clicked links attributes and text
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} $targetElement - the element that needs caused the need for a modal change
 */
function updateSaveForLaterCartStatus(data, $targetElement) {
    if (data.success && $targetElement.hasClass('move')) {
        var itemToMove = {
            actionUrl: $targetElement.data('nextaction'),
            productID: $targetElement.data('wishlistpid'),
            productName: $targetElement.data('productname'),
            uuid: $targetElement.data('uuid'),
            message: data.message,
            modalBodyDelete: data.modalBodyDelete
        };

        // listener prefills #removeProductModal
        confirmDelete(itemToMove);

        setTimeout(function () {
            $('#removeProductModal').modal();
        }, 1000);
    }
}

var saveForLater = function() {
    var element = $(this);
    var clickedData = element.data();

    base.destroySaveForLater(); //keep this from firing again
    $.ajax({
        url: clickedData.url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: clickedData.wishlistpid,
            quantity: 1 //Keep it simple and just add 1 of the product
        },
        success: function(data) {
            if (data.success && data.saveForLaterHTML) {
                if ($('.product-list-enhancements-detail-save-for-later').length) {
                    $('.product-list-enhancements-detail-save-for-later').replaceWith(data.saveForLaterHTML);
                } else {
                    $('.cart-page').append(data.saveForLaterHTML);
                }
                updateSaveForLaterCartStatus(data, element);
                $('body').trigger('saveforlater:afterHTMLswap');
                element.hide();
            } else {
                toast.methods.show('warning', data.message, null);
            }
            base.initSaveForLater();
        },
        error: function(data) {
            var errorMsg = $('.product-list-enhancements-detail-save-for-later').data('error-msg');
            toast.methods.show('warning', data && data.message ? data.message : errorMsg, null);
        }
    });
};

base.destroySaveForLater =  function() {
    $('body')
        .off('click', '.save-for-later-button', saveForLater)
        .off('saveforlater:initFromEmptyCart', function() {
            base.destroySaveForLater();
            base.initSaveForLater();
            base.saveForLaterBtnSwap();
        });
};

base.initSaveForLater =  function() {
    $('body')
        .on('click', '.save-for-later-button', saveForLater)
        .on('saveforlater:initFromEmptyCart', function() {
            base.destroySaveForLater();
            base.initSaveForLater();
            base.saveForLaterBtnSwap();
        });
};

base.saveForLaterBtnSwap = function() {
    if ($('.product-list-enhancements-toggle-product').hasClass('save-for-later-button')) {
        base.destroy();
    }
};

base.init = (function(init) {
    function extendsInit() {
        init();
        base.initSaveForLater();
        base.saveForLaterBtnSwap();
    }

    return extendsInit;
})(base.init);

module.exports = base;
