'use strict';

var PhotoSwipeLightbox = require('./photoswipe-lightbox').default;
var PhotoSwipe = require('./photoswipe');
var hiResImageWidth = 'sitePreferences' in window.CachedData && window.CachedData.sitePreferences.hiResImageWidth;

/**
 * Load up the photoswipe carousel
 */
function initPhotoSwipeCarousel(slideContainer) {
    if ($(slideContainer).length) {

        //gather how many added assets are in the slider
        var galleryAssets = $(slideContainer + ' .slide.pdp-gallery-asset').length;

        //check if product videos are under thumbnail slider
        var videoAssets = $(slideContainer + ' .pdp-video-asset').length;

        //Build out the main product slider's photoswipe
        if ($(slideContainer).hasClass('primary-images-main')) {
            $(slideContainer + ' .slide:not(.pdp-gallery-asset)').each( function(i, slide) {
                $(slide)
                    .attr('data-pswp-width', hiResImageWidth)
                    .attr('data-pswp-height', hiResImageWidth);
            });

            var mainSliderOptions = {
                gallery: slideContainer,
                children: '.slide:not(.pdp-gallery-asset)',
                pswpModule: () => PhotoSwipe,
                counter: false
            };

            const lightbox = new PhotoSwipeLightbox(mainSliderOptions);

            if (galleryAssets) {
                lightbox.addFilter('numItems', (numItems, dataSource) => {
                    return numItems - galleryAssets;
                });
            }

            lightbox.on('uiRegister', () => lightbox.pswp.ui.registerElement(bulletElement));

            lightbox.init();
        }

        //Build out the video asset's photoswipe
        if (videoAssets) {
            var videoAssetOptions = {
                gallery: slideContainer,
                children: '.pdp-video-asset',
                pswpModule: () => PhotoSwipe,
                counter: false,
                loop: videoAssets > 1,
                arrowKeys: videoAssets > 1,
                allowPanToNext: videoAssets > 1,
                arrowPrev: videoAssets > 1,
                arrowNext: videoAssets > 1,
                bgClickAction: 'close'
            };

            const videosLightbox = new PhotoSwipeLightbox(videoAssetOptions);

            // parse video-url attribute
            videosLightbox.addFilter('itemData', (itemData, index) => {
                const videoUrl = itemData.element.dataset.videoUrl;
                if (videoUrl) {
                    itemData.videoUrl = videoUrl;
                    itemData.video = itemData.element.dataset.video;
                }
                return itemData;
            });

            const UUID = 'uuid-' + Math.random();

            videosLightbox.on('initialLayout', () => {
                $('.pswp__preloader').addClass('active');
            });
            videosLightbox.on('bindEvents', () => {
                $('[data-uuid="' + UUID +'"]').ready( () => $('.pswp__preloader').removeClass('active'));
            });

            // override slide content
            videosLightbox.on('contentLoad', (e) => {
                const { content } = e;
                if (content.type === 'product-data-video-url') {
                    // prevent the deafult behavior
                    e.preventDefault();

                    // Create a container for iframe
                    // and assign it to the `content.element` property
                    content.element = document.createElement('div');
                    content.element.className = 'video-container aspect-ratio-16-9';

                    const iframe = document.createElement('iframe');
                    iframe.setAttribute('class', 'video-player');
                    iframe.setAttribute('data-video', content.data.video);
                    iframe.setAttribute('allowfullscreen', '1');
                    iframe.setAttribute('data-uuid', UUID);
                    iframe.setAttribute('frameborder', '0');
                    iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share');
                    iframe.src = content.data.videoUrl;

                    content.element.appendChild(iframe);
                }
            });

            videosLightbox.on('contentDeactivate', ({ content }) => pauseAllVideos(content));

            videosLightbox.on('uiRegister', () => videosLightbox.pswp.ui.registerElement(bulletElement));

            videosLightbox.init(); //creating a new one just for video assets since pswp keeping img divs separate from content divs

            $('.video-asset-links-trigger').on('click', () => {
                videosLightbox.loadAndOpen(0, { //0 is the index of which slide to open to
                    gallery: document.querySelector('.video-asset-links')
                });
            });
        }
    }
};

var pauseAllVideos = (content) => {
    var videos = $(content.element).closest('.pswp__container').find('iframe');
    videos.each( (index, video) => {
        video.contentWindow.postMessage(messageFunction('pause', video.src), '*');
    });
}

var messageFunction = (action, src) => {
    if (src.search("vimeo") > 0) { // case for Vimeo
        return JSON.stringify({
            method: action
        });
    } else if (src.search("youtube") > 0) { // case for youTube
        return JSON.stringify({
            event: 'command',
            func: action + 'Video'
        });
    } else { // case for other video
        return action;
    }
}

var bulletElement = {
    name: 'bulletsIndicator',
    className: 'pswp__bullets-indicator',
    appendTo: 'wrapper',
    onInit: (el, pswp) => {
        const bullets = [];
        let bullet;
        let prevIndex = -1;

        for (let i = 0; i < pswp.getNumItems(); i++) {
            bullet = document.createElement('div');
            bullet.className = 'pswp__bullet';
            bullet.onclick = (e) => {
                pswp.goTo(bullets.indexOf(e.target));
            };
            el.appendChild(bullet);
            bullets.push(bullet);
        }

        pswp.on('change', (a,) => {
            if (prevIndex >= 0) {
                bullets[prevIndex].classList.remove('pswp__bullet--active');
            }
            bullets[pswp.currIndex].classList.add('pswp__bullet--active');
            prevIndex = pswp.currIndex;
        });
    }
};

module.exports = {
    initPhotoSwipeCarousel: initPhotoSwipeCarousel
}