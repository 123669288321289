/* globals google */
'use strict';

var base = require('core/storeLocator/storeLocator');

//set the center of the map to canada if applicable
var lat = 37.09024,
    long = -95.712891;
if (window.CachedData?.siteContext?.ID == 'samsoniteCA') lat = 56.1304; long = -106.3468;

var initFilter = false;


/**
 * Uses google maps api to render a map
 */
function maps() {
    var map;
    var infowindow = new google.maps.InfoWindow();
    var mapdiv = $('.map-canvas').attr('data-locations');
    var bounds = new google.maps.LatLngBounds();
    var zoom = 4;

    mapdiv = JSON.parse(mapdiv);

    if (mapdiv.length === 1) {
        zoom = 18; // set zoom more specifically if single store
    }

    // Init U.S. Map in the center of the viewport
    var latlng = new google.maps.LatLng(lat, long);
    var mapOptions = {
        scrollwheel: false,
        zoom: zoom,
        center: latlng
    };

    map = new google.maps.Map($('.map-canvas')[0], mapOptions);

    Object.keys(mapdiv).forEach(function (key) {
        var item = mapdiv[key];
        var label = parseInt(key, 10) + 1;
        var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);

        var marker = new google.maps.Marker({
            position: storeLocation,
            type: item.storeType.value,
            map: map,
            title: item.name,
            icon: {
                url: item.backgroundImage,
                size: new google.maps.Size(45, 45),
                scaledSize: new google.maps.Size(45, 45),
                labelOrigin: new google.maps.Point(24, 17)
            },
            label: {
                text: label.toString(),
                color: item.color,
                fontSize: '14px'
            }
        });

        marker.addListener('click', function () {
            infowindow.setOptions({
                content: item.infoWindowHtml
            });
            infowindow.open(map, marker);
        });

        // Create a minimum bound based on a set of storeLocations
        bounds.extend(marker.position);

        // If there is only one store, center the map on that store
        if (mapdiv.length === 1) {
            map.setCenter(storeLocation);
        }
    });
    // Fit the all the store marks in the center of a minimum bounds when any store has been found, unless there is a single store result
    if (mapdiv && mapdiv.length > 1) {
        map.fitBounds(bounds);
    }
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data, scope) {
    var $resultsDiv = scope.find('.results');
    var $mapDiv = $('.map-canvas'); //Not scoped to support multiple maps on page yet
    var hasResults = data.stores.length > 0;

    if (!hasResults) {
        if ('noInventoryStores' in data) {
            scope.find('.store-locator-no-results-for-product').show();
        } else {
            scope.find('.store-locator-no-results').show();
        }

        if (data.allStores > 0) {
            scope.find('.filter-results').show();
        } else {
            scope.find('.filter-results').hide();
        }

        $resultsDiv.attr('data-has-results', false);
    } else {
        scope.find('.store-locator-no-results').hide();
        scope.find('.store-locator-no-results-for-product').hide();
        scope.find('.filter-results').show();
        $resultsDiv.attr('data-has-results', true);
    }

    $resultsDiv.empty()
        .data('has-results', hasResults)
        .data('radius', data.radius)
        .data('search-key', data.searchKey)
        .data('store-type', data.storeType);

    $mapDiv.attr('data-locations', data.locations);

    if ($mapDiv.data('has-google-api')) {
        module.exports.methods.googleMapsPromise.then(() => module.exports.methods.maps());
    } else {
        $('.store-locator-no-apiKey').show();
    }

    if (data.storesResultsHtml) {
        $resultsDiv.append(data.storesResultsHtml);
    }

    if (data.noInventoryStoresHtml) {
        $resultsDiv.append(data.noInventoryStoresHtml);
    }

    if (hasResults || data.allStores > 0) {
        $.each(data.storeTypes, function(storeType) {
            let storeTypeValue = data.storeTypes[storeType].value;
            let selectedStoreTypeValue = data.storeTypeValue;

            scope.find('.filter-results [data-filter="' + storeTypeValue + '"]').show();

            if (storeTypeValue === selectedStoreTypeValue) {
                scope.find('.filter-results [data-filter="' + storeTypeValue + '"] span').text(data.stores.length);
            } else {
                let storeTypeStores = data[storeTypeValue + 'Stores'];
                if (storeTypeStores > 0) {
                    scope.find('.filter-results [data-filter="' + storeTypeValue + '"] span').text(storeTypeStores);
                }
            }
        });
        scope.find('.filter-results [data-filter="all"] span').text(data.allStores);
    }

    if (!initFilter) {
        module.exports.filterResults();
        initFilter = true;
    }

    $.each($('.filter-results .btn'), function (i) {
        var $el = $($('.filter-results .btn')[i]);
        var storeTypeValue = data.storeTypeValue;
        if ($el.data('filter') === storeTypeValue) {
            $el.addClass('btn-primary');
            $el.removeClass('btn-secondary');
        } else {
            $el.addClass('btn-secondary');
            $el.removeClass('btn-primary');
        }
    });
}

function changeRadius() {
    $('body').on('change', '.store-locator-container .radius', function () {
        var radius = $(this).val();
        var scope = $(this).closest('.store-locator-container');
        var searchKeys = scope.find('.results').data('search-key');
        var url = $(this).data('action-url');
        var urlParams = {};

        if (searchKeys.postalCode) {
            urlParams = {
                radius: radius,
                postalCode: searchKeys.postalCode.replace(' ', '')
            };
        } else if (searchKeys.lat && searchKeys.long) {
            urlParams = {
                radius: radius,
                lat: searchKeys.lat,
                long: searchKeys.long
            };
        }

        scope.find('.filter-results [data-filter]').removeClass('active btn-primary').addClass('btn-secondary').hide();
        scope.find('.filter-results [data-filter="all"]').addClass('active btn-primary').removeClass('btn-secondary').show();

        url = module.exports.methods.appendToUrl(url, urlParams);
        var dialog = $(this).closest('.in-store-inventory-dialog');
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                module.exports.methods.updateStoresResults(data, scope);
                scope.find('.select-store').prop('disabled', true);
            },
            complete: function () {
                spinner.stop();
            }
        });
    });
}

function filterResults() {
    var $filters = $('.filter-results [data-filter]');

    $filters.on('click', function (e) {
        e.preventDefault();
        var scope = $(this).closest('.store-locator-container');

        $filters.removeClass('active btn-primary').addClass('btn-secondary');
        $(this).addClass('active btn-primary').removeClass('btn-secondary');

        var $filterType = $(this).attr('data-filter');
        var radius = scope.find('.results').data('radius');
        var searchKeys = scope.find('.results').data('search-key');
        var url = scope.find('.filter-results').data('action-url');
        var urlParams = {};

        if (searchKeys.postalCode) {
            urlParams = {
                radius: radius,
                postalCode: searchKeys.postalCode.replace(' ', ''),
                storeType: $filterType
            };
        } else if (searchKeys.lat && searchKeys.long) {
            urlParams = {
                radius: radius,
                lat: searchKeys.lat,
                long: searchKeys.long,
                storeType: $filterType
            };
        }

        url = module.exports.methods.appendToUrl(url, urlParams);
        var dialog = scope.find('.radius').closest('.in-store-inventory-dialog');
        var spinner = dialog.length ? dialog.spinner() : $.spinner();
        spinner.start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                module.exports.methods.updateStoresResults(data, scope);
                scope.find('.select-store').prop('disabled', true);
            },
            complete: function () {
                spinner.stop();
            }
        });

    });
}

base.methods.maps = maps;
base.changeRadius = changeRadius;
base.filterResults = filterResults;
base.methods.updateStoresResults = updateStoresResults;
module.exports = base;
