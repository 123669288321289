'use strict';

var core = require('core/components/header');

module.exports = function () {

    $('form[name=simpleSearch]').on('keyup submit', event => {
        if (event.currentTarget.length) {
            var $input = $('input[name="q"]', event.currentTarget);
            //keep aria invalid at zero characters
            $input.attr('aria-invalid', $input[0].value == "");

            if (event.type == 'submit' && $input[0].value == "") {
                var errorMessage = $input.attr('aria-description');
                // Create an aria-alert with the error message
                var alert = document.createElement('div');
                alert.setAttribute('role', 'alert');
                alert.setAttribute('aria-live', 'assertive');
                alert.classList = 'alert alert-warning fade show toast-messages mt-4';
                alert.id = 'search-error-alert';
                alert.textContent = errorMessage;
                document.body.appendChild(alert);

                // Focus back to the alert for user convenience
                alert.focus();

                // Timeout after 3 seconds
                setTimeout(function() {
                    document.body.removeChild(alert);
                }, 3000);

                // Remove alert on hover of another element
                document.addEventListener('mouseover', function(event) {
                    if (event.target !== alert) {
                        document.body.removeChild(alert);
                    }
                });
            }
        }
    });

    return core.apply(this, arguments);

};