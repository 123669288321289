function pushToDataLayer(event, callback) {
    if (typeof jQuery === "undefined" || typeof $ === "undefined" || typeof window.dataLayer === "undefined") {
        setTimeout(() => pushToDataLayer(event, callback), 100);
        return;
    }

    const dataLayer = window.dataLayer || [];
    dataLayer.push(event);
    if (callback) {
        callback();
    }
}

module.exports = {
    PushToDataLayer: pushToDataLayer
};