'use strict';

var SiteConstants = require('constants/SiteConstants');
var sitePreferencesUtils = require('core/preferences/sitePreferencesUtils');
var keyboardAccessibility = require('base/components/keyboardAccessibility');
var focusHelper = require('base/components/focus');
var preferences = sitePreferencesUtils.getSitePreferences();
var miniCartAddToCartTimeout = preferences.miniCartAddToCartTimeout;
var miniCartPostHoverTimeout = preferences.miniCartPostHoverTimeout;
var updateMiniCart = true;
var minicartTimeoutID, miniCartLeaveID;

function setMiniCartBodyMaxHeight(minicart) {
    var $minicart = $(minicart);
    var $minicartScrollable = $minicart.find('.product-summary');
    var minicartHeight = $minicart.outerHeight();
    var minicartScrollableHeight = $minicartScrollable.outerHeight();
    var minicartFooterHeight = $minicart.find('.minicart-footer').outerHeight();
    var minicartNonScrollableHeight = minicartHeight - minicartScrollableHeight;
    var minicartOffset = $minicart.offset().top - $(window).scrollTop();
    var subtractHeight = minicartOffset + minicartNonScrollableHeight + SiteConstants.Spacer + minicartFooterHeight;

    $minicartScrollable.css('max-height', 'calc(100cqh - ' + subtractHeight + 'px)');
}

function isNavInViewport() {
    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + window.innerHeight;
    var navTop = $('#header-nav').offset().top;
    var navBottom = navTop + $('#header-nav').height();

    return navBottom > viewportTop && navTop < viewportBottom;
}

function showMiniCartBackdrop () {
    $('.minicart-backdrop').addClass('show');
    $('body').addClass('minicart-open');
}

var focusableElements = 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex], *[contenteditable]'

function keyboardEventMiniCartClose(e) {
    if (e) {
        $(e).trigger('blur').blur();
        $(e).trigger('minicart:timeout');
        $('body').off('keydown', '.minicart .popover .cart');
        if ($('.product-detail').length) $('.product-detail').find('.add-to-cart').trigger('focus');
        else if ($('.product').length) $('.product').eq(0).trigger('focus');
        module.exports.methods.hideMiniCartBackdrop();
    }
}


function hideMiniCartBackdrop (forceClose) {
    if ($('.minicart').find('.focus-visible')) $('.minicart').find('.focus-visible').trigger('blur').blur();
    if (forceClose || ($('.minicart:hover').length === 0 && $('.minicart .popover:hover').length === 0)) {
        $('.minicart .popover').removeClass('show');
        $('.minicart-backdrop').removeClass('show');
        $('body').removeClass('minicart-open');
        $('.minicart .popover').add($('.minicart .popover').find(focusableElements)).attr('tabindex', -1).attr('aria-hidden', true);
        $('.minicart-link').trigger('focus');
    }
}

function initMinicartKeyboardAccess () {
    $('.minicart .popover').add($('.minicart .popover').find(focusableElements)).attr('tabindex', 0).attr('aria-hidden', false);
    keyboardAccessibility('.close-minicart', {
        32: function (e) { keyboardEventMiniCartClose(e) }  // spacebar
        }, function () { return $(this); }
    );
    keyboardAccessibility('.minicart .popover .cart', {
        27: function (e) { keyboardEventMiniCartClose(e) }  // esc
        }, function () { return $(this); }
    );
    $('body').on('keydown', '.minicart .popover .cart', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '.minicart .popover .cart',
            firstElementSelector: '.close-minicart',
            lastElementSelector: '.minicart-footer .checkout-btn',
            nextToLastElementSelector: '.minicart-footer .cart-link'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
    $('.popover .minicart-footer .cart-link').trigger('focus');
}

module.exports = {
    methods: {
        setMiniCartBodyMaxHeight: setMiniCartBodyMaxHeight,
        isNavInViewport: isNavInViewport,
        showMiniCartBackdrop: showMiniCartBackdrop,
        hideMiniCartBackdrop: hideMiniCartBackdrop
    },
    init: function () {
        $('.minicart').on('count:update', (event, count) => {
            if (count && $.isNumeric(count.quantityTotal)) {
                $('.minicart .minicart-quantity').text(count.quantityTotal);
                $('.minicart .minicart-link').attr({
                    'aria-label': count.minicartCountOfItems,
                    title: count.minicartCountOfItems
                });
            }
        });

        $('.minicart').on('hover mouseenter minicart:show keyup minicart:keyboard:show', (event) => {
            clearTimeout(miniCartLeaveID);
            if (event.type == 'keyup' && event.which !== 40) {
                clearTimeout(minicartTimeoutID);
                $('.minicart-label').removeClass('item-added');
                return;
            }
            if ($('.search:visible').length === 0 && event.type !== 'minicart:show') {
                return;
            }
            var url = $('.minicart').data('action-url');
            var count = parseInt($('.minicart .minicart-quantity').text(), 10);
            var $backdrop = $('.minicart-backdrop');

            if ($backdrop.data('backdrop') !== 'off' && count !== 0) {
                var eventType = event.type;
                if (($backdrop.data('backdrop') == 'on_hover' && eventType !== 'minicart:show')
                    || ($backdrop.data('backdrop') == 'on_atc' && eventType === 'minicart:show')
                    || ($backdrop.data('backdrop') == 'on')) {
                    module.exports.methods.showMiniCartBackdrop();
                }
            }

            if (count !== 0 && $('.minicart .popover.show').length === 0) {
                if (!updateMiniCart && !$('.minicart .popover').is(':empty')) {
                    $('.minicart .popover').addClass('show');
                    initMinicartKeyboardAccess();
                    return;
                }

                $('.minicart .popover').addClass('show');
                $('.minicart .popover').spinner().start();
                $.get(url, data => {
                    $('.minicart .popover .cart').length ? $('.minicart .popover .cart').replaceWith(data) : $('.minicart .popover').prepend(data);
                    updateMiniCart = false;
                    if (event.type === 'minicart:show') {
                        $('.minicart-label').addClass('item-added');
                    }
                    $('body').trigger('minicart:loaded', $('.minicart .popover'));
                    $.spinner().stop();
                });
            }

            if (event.type == 'minicart:show') {
                clearTimeout(minicartTimeoutID);
                minicartTimeoutID = setTimeout(function () {
                    // check to make sure minicart icon and popover aren't being hovered over before sending the timeout event
                    if (($('.minicart:hover').length === 0 && $('.minicart .popover:hover').length === 0) && $('.minicart .popover .focus-visible').length === 0) {
                        $('body').trigger('minicart:timeout');
                    }
                    $('.minicart-label').removeClass('item-added');
                }, miniCartAddToCartTimeout);
            };

            if (event.type == 'minicart:keyboard:show') initMinicartKeyboardAccess();
        });

        $('.minicart').on('minicart:keyboard:hide', function (e) {
            keyboardEventMiniCartClose(e);
        });

        $('body').on('minicart:loaded', () => {
            $('.popover .minicart-footer .cart-link').one().trigger('focus');
            initMinicartKeyboardAccess();
        });

        $('.minicart').on('touchstart click minicart:timeout', event => {
            if (($('.minicart').has(event.target).length <= 0)
                || ($('.close-minicart').has(event.target).length > 0)
                || event.target.classList.contains('close-minicart')) { // Customization to close minicart on click of close button
                    var forceClose = true;
                    module.exports.methods.hideMiniCartBackdrop(forceClose);
            }
        });

        $('body').on('minicart:timeout', event => {
            if (($('.minicart').has(event.target).length <= 0)
                || ($('.close-minicart').has(event.target).length > 0)
                || event.target.classList.contains('close-minicart')) { // Customization to close minicart on click of close button
                    var forceClose = true;
                    module.exports.methods.hideMiniCartBackdrop(forceClose);
            }
            
            if ($('header').hasClass('transparent-header')) {
                $('#header-nav').addClass('transparent-nav');
            }
        })

        $('.minicart').on('mouseleave focusout blur', event => {
            clearTimeout(miniCartLeaveID);
            miniCartLeaveID = setTimeout(function () {
                if ((event.type === 'focusout' && $('.minicart').has(event.target).length > 0)
                || (event.type === 'mouseleave' && $(event.target).is('.minicart .quantity'))
                || $('body').hasClass('modal-open')) {
                    event.stopPropagation();
                    return;
                }
                module.exports.methods.hideMiniCartBackdrop();
                $('.minicart-label').removeClass('item-added');
                $('body').trigger('minicart:mouseleave_focusout');
            }, miniCartPostHoverTimeout);
        });

        $('body').on('change', '.minicart .quantity', event => {
            if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
                location.reload();
            }
        });

        $('body').on('product:afterAddToCart cart:update', () => {
            updateMiniCart = true;
        });

        $('body').on('product:afterAddToCart', () => {
            if (miniCartAddToCartTimeout > 0) {
                $('.add-to-cart-messages').addClass('sr-only');

                // if Enhanced Header is set and if nav is not in viewport, we add the appropriate classes to tap into AB's sticky header reveal.
                // if header nav IS in viewport, we clear the transparent-nav class to simulate the transition of the user hovering the nav.
                // transparent-nav class is reapplied at minicart display timeout
                var hasFixedHeaderEnhanced = $('header').hasClass('fixed-header-enhanced');
                if (module.exports.methods.isNavInViewport()) {
                    if ($('header').hasClass('transparent-header')) {
                        $('#header-nav').removeClass('transparent-nav');
                    }
                } else if (hasFixedHeaderEnhanced) {
                    $('html').addClass('sticky-header scroll-direction-up');
                }

                // gives time for a quickview modal to close before displaying minicart
                setTimeout(function(){
                    $('.minicart').trigger('minicart:show');
                }, 500);
            }
        });

        $('body').on('minicart:loaded', (event, minicart) => {
            module.exports.methods.setMiniCartBodyMaxHeight(minicart);
        });
    }
};
