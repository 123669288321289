'use strict';

var abSlider = require('core/components/slider');
var tinySlider = require('tiny-slider/src/tiny-slider');
var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

// hijacking this fn to addd the prev button display event listener
abSlider.handleThumbnailArrows = function($sliderContainer, slider, updatedSlideItemsCount) {
    var numOfSlides = updatedSlideItemsCount ? updatedSlideItemsCount : $sliderContainer.data().sliderItems;
    var maxNumOfSlides = parseFloat($sliderContainer.data().sliderItemsMaxDisplaySitePref);

    if (numOfSlides && maxNumOfSlides) {
        //Add new css vars to the slider container parent to override the default vars set in the slider-container stylesheet
        if (numOfSlides <= maxNumOfSlides) {
            $sliderContainer.find('.slider-thumbnail-controls').removeClass('d-md-block');
            $sliderContainer.attr('data-slider-items-max-display', numOfSlides);
            $sliderContainer.parent().attr('style', `--max-number-of-slides: ${numOfSlides}; --thumbnail-product-slider-number-of-slides: ${numOfSlides};`);
        } else {
            $sliderContainer.find('.slider-thumbnail-controls').addClass('d-md-block');
            $sliderContainer.parent().attr('style', `--max-number-of-slides: ${maxNumOfSlides}; --thumbnail-product-slider-number-of-slides: ${numOfSlides};`);
        }
    }

    abSlider.handlePrevButtonDisplay($sliderContainer);
}

// Determine whether to show thumbnail under prev button
abSlider.handlePrevButtonDisplay = function($sliderContainer) {
    var $prev = $sliderContainer.find('.prev');
    var sliderControlsVisible = $sliderContainer.find('.slider-thumbnail-controls.d-md-block').length;

    if ($prev.length) {
        $('body').on('click', $sliderContainer.find('.slider-thumbnail-controls [class*="icon-arrow-"]'), function (e) {
            var $this = $(e.target);
            var $thisSliderContainer = $($this).closest('.slider-container');
            var $tnsOuter = $thisSliderContainer.find('.tns-outer');

            if (sliderControlsVisible && $thisSliderContainer.find('.prev').attr('aria-disabled') == 'false') {
                if (!$tnsOuter.hasClass('prev-button-showing')) {
                    $tnsOuter.addClass('prev-button-showing');
                }
            } else {
                $tnsOuter.removeClass('prev-button-showing');
            }
        })
    }
}

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, assets, isThumbnail) {
    var slideItems = [];
    var modalCarousel = window.CachedData?.sitePreferences?.zoomOrModalCarouselImage === 'modal' || false;

    images['large'].forEach(image => {
        var $slideElement;

        if (modalCarousel) {
            $slideElement = $('<a></a>').attr('class', 'slide no-zoom');
        } else {
            $slideElement = $('<div></div>').attr('class', 'slide');
        }

        var $imageElement = $('<img/>');

        if (!isThumbnail) {
            if (modalCarousel) {
                $slideElement.attr('href', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
            } else {
                $slideElement.attr('data-zoom-image', images['hi-res'].length ? images['hi-res'][image.index].url : images['large'][image.index].url);
            }
        }

        $imageElement.attr({
            'src': image.url,
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid',
            'itemprop': 'image'
        });

        $slideElement.append($imageElement);
        slideItems.push($slideElement[0]);
    });

    abSlider.getPDPGalleryAssetSlides(slideItems, assets, isThumbnail);

    return slideItems;
}

// Fetch any additional slides needed from pdpVideoAssets attribute
abSlider.getVideoAssetContainer = function($sliderContainer, videoAssets) {
    var $videoAssets = '';
    var $videoAssetClone = $sliderContainer.find('.pdp-video-assets').clone();

    if (videoAssets && videoAssets.length && $videoAssetClone && $videoAssetClone.length) {
        var host = $videoAssetClone.attr('data-host') || '';
        var $videoAssetContainer = $('<div class="video-asset-links"></div>');

        videoAssets.forEach((asset, index) => {
            var $slideElement = $('<a class="pdp-video-asset" data-pswp-type="product-data-video-url"></a>');

            var assetAttrs = {};
            if (asset.type === 'youtube') {
                assetAttrs = {
                    'href': 'https://youtu.be/' + asset.id,
                    'data-video': 'youtube',
                    'data-video-url': 'https://www.youtube.com/embed/' + asset.id + '?autoplay=0&playsinline=0&controls=1&mute=0&loop=0&enablejsapi=1&modestbranding=1&origin=' + host + '&widget_referrer=' + host + '&widgetid=2'
                };
            } else if (asset.type === 'vimeo') {
                assetAttrs = {
                    'href': 'https://vimeo.com/' + asset.id,
                    'data-video': 'vimeo',
                    'data-video-url': 'https://player.vimeo.com/video/' + asset.id + '?title=0&byline=0&portrait=0'
                };
            } else {
                assetAttrs = {
                    'href': asset.id,
                    'data-video': 'hosted',
                    'data-video-url': asset.id
                };
            }

            $slideElement.attr(assetAttrs);
            $videoAssetContainer.append($slideElement)
        });

        $videoAssetClone.find('.video-asset-links').remove();
        $videoAssets = $videoAssetClone.append($videoAssetContainer);
    }

    return $videoAssets;
}

// Listen for the slider:update event and reinitialize slider with new slides
abSlider.bindSliderUpdateEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:update').on('slider:update', '.slider', (event, data) => {
        var $updatedSliderContainer = $(event.target).closest('.slider-container');
        var isThumbnail = $updatedSliderContainer.hasClass('slider-thumbnails');
        var updatedSlideItems = data !== undefined ? abSlider.getUpdatedSlideItems(data.images, data.assets, isThumbnail) : null;
        var updatedSlideItemsCount = updatedSlideItems ? updatedSlideItems.length : null;
        var $videoAssets = data !== undefined && isThumbnail ? abSlider.getVideoAssetContainer($sliderContainer, data.videoAssets) : null; // only add the video assets to the thumbnail slider to trigger a new photoswipe
        var updatedParams = abSlider.getSliderParams($updatedSliderContainer, updatedSlideItems);

        // Update carousel classes to handle number of images changing
        if (isThumbnail) {
            var $thumbnailContainer = $updatedSliderContainer.closest('.primary-images-thumbnails');
            var $mainImageContainer = $thumbnailContainer.prev('.primary-images-main');

            if (data.images.small && data.images.small.length > 1) {
                $thumbnailContainer.removeClass('d-none');
                $mainImageContainer.addClass('product-has-thumbnails col-lg-9');
            } else {
                $thumbnailContainer.addClass('d-none');
                $mainImageContainer.removeClass('product-has-thumbnails col-lg-9');
            }
        }

        // Remove and re-add slider markup if there are new slides to show
        if (updatedParams.slideItems) {
            var $newSliderElement = $('<div></div>').attr({
                'class': 'slider',
                'id': updatedParams.container.id
            });
            var $newSliderControls = $updatedSliderContainer.find('.slider-thumbnail-controls').addClass('d-none');
            $newSliderElement.html(updatedParams.slideItems);
            $updatedSliderContainer.html($newSliderElement);
            if ($videoAssets && $videoAssets !== '') $updatedSliderContainer.append($videoAssets);
            updatedParams.container = $newSliderElement[0];

            // If this is a thumbnails slider, also add the arrow controls and update the item count
            if (isThumbnail) {
                $updatedSliderContainer.append($newSliderControls);
                updatedParams.items = updatedParams.slideItems.length;
                updatedParams.responsive[mediumBreakpoint].items = updatedParams.slideItems.length;
                updatedParams.responsive[largeBreakpoint].items = updatedParams.slideItems.length;
            }
        }

        // Reinitialize slider using new params
        slider = tinySlider.tns(updatedParams);
        slider.events.on('transitionEnd', abSlider.slideIntoView);
        abSlider.enableImageZoom($updatedSliderContainer);

        if (isThumbnail) {
            abSlider.handleThumbnailArrows($updatedSliderContainer, slider, updatedSlideItemsCount);
        } else {
            // listen for videos getting updated from getUpdatedSlideItems function
            $('body').on('slider:videosLoaded', () => {
                abSlider.handleSliderVideos($updatedSliderContainer, slider);
            });
        }
    });
}

module.exports = abSlider;
