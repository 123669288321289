'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

function changeLocale(e) {
    var action = $('.page').data('action');
    var localeCode = $(e).data('locale');
    var localeCurrencyCode = $(e).data('currencycode');
    var queryString = $('.page').data('querystring');
    var url = $('.country-selector').data('url');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: {
            code: localeCode,
            queryString: queryString,
            CurrencyCode: localeCurrencyCode,
            action: action
        },
        success: function (response) {
            $.spinner().stop();
            if (response && response.redirectUrl) {
                window.location.href = response.redirectUrl;
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
}

module.exports = function () {
     // initialize action to allow default behavior
     var $page = $('.page');

     if (['', null, undefined].indexOf($page.data('action')) !== -1) $page.data('action','Home-Show');

    $('.set-locale a').on('click', function (e) {
        e.preventDefault();
        changeLocale($(this));
    });

    keyboardAccessibility('.set-locale a',
        {
            13: function(e) { // enter
                changeLocale(e);
            },
            32: function(e) { // spacebar
                changeLocale(e);
            }
        },
        function () {
            return $(this);
        }
    );

    $('.navbar-header .country-selector').on('focusin', function () {
        $(this).addClass('show').children('.dropdown-menu').addClass('show');
    });
};
