'use strict';

var base = require('core/product/base');
var baseUpdateAddToCart = base.updateAddToCart;
var toastHelpers = require('core/components/toast');
var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
var abSlider = require('../components/slider');


// Modification from base to handle updating show/hide of in-stock icon
base.updateAvailabilityIcon = function () {
    $('body').on('product:updateAvailability', function (e, response) {
        var $productContainer = response.$productContainer;

        $productContainer.find('.product-availability')
            .find('.in-stock-checkmark')[response.product.available ? 'removeClass' : 'addClass']('d-none');
    });
}

base.updateSizeChartLinks = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        $.each(response.container.find('.size-chart-modal'), function(i, sizeChart) {
            var $sizeChart = $(sizeChart);
            var type = $sizeChart.closest('.size-fit-links').data().sizeType;
            $sizeChart.attr('data-product', type + '-' + response.data.product.id);
        })
    });
}

base.sizeChartOpener = function () {
    $('body').on('click', '.size-chart-launcher', function (event) {
      event.preventDefault();
      var url = $(event.target).attr('href');
      var sizeType = $(event.target).closest('.size-fit-links').data().sizeType;
      var productId = $(event.target).closest('.product-detail').data().pid;
      var $sizeChartModal = $('.modal[data-product='+ sizeType + '-' + productId + ']');
      var $modalBody = $sizeChartModal.find('.modal-body');
      $.ajax({
        url: url,
        type: 'get',
        dataType: 'html',
        success: function success(data) {
          $modalBody.html(data);
        }
      });
      //if the sizechart is from a quickview append after all the modal-backdrops
      if ($(event.target).parents('.product-quickview').length) {
        var $sizeChartContainer = $(event.target).closest('.size-fit-links');
        $sizeChartModal.appendTo('body');
        $sizeChartModal.on('hide.bs.modal', function (event) {
          $sizeChartModal.appendTo($sizeChartContainer);
        });
      }

      $sizeChartModal.modal('show');
    });
    $('body').on('click', '.size-chart-modal .close', function (event) {
      $(event.target).closest('.size-chart-modal').modal('hide');
    });
}

// BazaarVoice Reviews/Q&A selection JS
base.bvReviewsAndQuestions = function () {
    $('ul.review-qa-tabs li a').on('click',function() {
        var selectedBVTabName = $(this).data('tab-name');

        $(this).parent().parent().find('.bv-tab').removeClass('active');
        $(this).addClass('active');

        $('#reviewqa div.row').each(function() {
            var bvSection = $(this);

            if (bvSection.hasClass(selectedBVTabName) && !bvSection.hasClass('active')) {
                bvSection.addClass('active');
            } else if (!bvSection.hasClass(selectedBVTabName) && bvSection.hasClass('active')) {
                bvSection.removeClass('active');
            }
        });
    });
}

base.handleTangiblee = function () {
    var DataLayerHelper = require('../utilities/dataLayerHelper');
    const $defaultColorSwatch = $('.color-value.default');
    const data = {
        'color': {
            'displayName': 'Color',
            'value': $defaultColorSwatch.attr('data-attr-value'),
            'displayValue': $defaultColorSwatch.attr('data-displayvalue')
        }
    };
    $('.product-variations').attr('data-current', JSON.stringify(data));

    // After JSON has been loaded, trigger GTM event to load tangiblee
    DataLayerHelper.PushToDataLayer({ 'event': 'loadTangibleePDP' });
}

/**
 * Replace handleVariantResponse function to include promotion percentage messaging
 */
base.methods.handleVariantResponse = function(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    var isSetItem = $productContainer.hasClass('product-set-item-detail') ? true : false;
    var $badgesContainer=$('.badge-product-container');
    if (response.product.variationAttributes) {
        base.methods.updateAttrs(response.product.variationAttributes, $productContainer, response.resources);
        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    if (response.product.badges.length > 0) {
        $badgesContainer.empty();
        response.product.badges.forEach(function (badge) {
            var $badge = document.createElement('span');
            $badge.className="badge-product " + badge.class;
            $badge.append(badge.name);
            $badgesContainer.append($badge);
        });
    } else {
        $badgesContainer.empty();
    }

    // Update primary images
    var primaryImages = response.product.images;
    var pdpGalleryAssets = response.product.pdpGalleryAssets;
    var pdpVideoAssets = response.product.pdpVideoAssets;
    var $oldWishlistIcon = $productContainer.find('.slide .product-list-enhancements-toggle-product').first().clone(true);

    if ($productContainer.find('.image-grid-outer .image-grid-inner').length > 0) {
        var $imageGridContainer = $productContainer.find('.image-grid-inner');
        var images = primaryImages;
        var data = images !== null ? {images} : null;
        var slides = abSlider.getUpdatedSlideItems(data.images, pdpGalleryAssets, false);
        var $videoAssetClone = $imageGridContainer.find('.pdp-video-assets').clone();
        $imageGridContainer.html(slides).append($videoAssetClone);
    } else {
        base.methods.createSlider(primaryImages, pdpGalleryAssets,  pdpVideoAssets, $productContainer.find('.primary-images'));
    }

    // if variant is a product set item, update the sample image
    if (isSetItem) {
        $productContainer
            .find('.product-set-item-main-image')
            .attr('src', primaryImages.large[0].url)
            .attr('alt', primaryImages.large[0].alt);
    }

    // Update pricing
    var isQuickview = $productContainer.hasClass('product-quickview') ? true : false;
    var $priceContainer = isQuickview ? $productContainer.closest('.modal-content') : $productContainer;

    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $priceContainer.find('.prices .price', $priceContainer).length
            ? $priceContainer.find('.prices .price', $priceContainer)
            : $priceContainer.find('.prices .price');
        $priceSelector.replaceWith(response.product.price.html);
    }

    //pulling out this code from the bonusproduct check since bonus products can also be on-sale and have a percentage off
    if (response.product.showPercentageOff && response.product.price.percentageOff) {
        if ($priceContainer.find('.top-prices .default-pricing .percent-off').length) {
            $priceContainer.find('.top-prices .default-pricing .percent-off').remove();
        }
        $priceContainer.find('.top-prices .default-pricing').append('<span class="percent-off">' + response.product.price.percentageOff + '</span>');
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(response.product.promotionsHtml);

    base.methods.updateAvailabilityProcess(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product, $productContainer: $productContainer
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
            product: response.product, $productContainer: $productContainer
        }).trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist
    if ($oldWishlistIcon && $oldWishlistIcon.length) {
        var $newWishlistIcon = $oldWishlistIcon;
        $newWishlistIcon.attr('data-wishlistpid', response.product.wishlistpid);

        //Make heart icon accurate
        var wishlist = require('core/productListEnhancements/helpers.js');
        wishlist.updateLinkData($newWishlistIcon);

        var $newSliderMainImages = $productContainer.find('.primary-images-main .slide img');
        $newSliderMainImages.each((_i, newImage) => {
            var $newImage = $(newImage);
            $newImage.after($newWishlistIcon.clone(true));
        });
    }
}

base.addToCart = function () {
    var scope = this;

    // if qty stepper input has focus, we need to blur it so product data can update before button click
    $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: scope.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                addToCartUrl = $(this).data('url');
            } else {
                $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                var $productModalbody = $(this).closest('.modal-content');
                addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer)
        };

        // SAS CHANGE: adding error check before triggers
        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (!data.error) {
                        scope.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                        $.spinner().stop();
                        scope.methods.miniCartReportingUrl(data.reportingURL);
                    } else {
                        toastHelpers.methods.show('warning', data.message, false);
                        $.spinner().stop();
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

base.nonColorAttribute = function () {
    var scope = this;

    $(document).off('click', 'button.swatch:not(.color-attribute)').on('click', 'button.swatch:not(.color-attribute)', function (e) {
        e.preventDefault();

        if ($('.product-comparison-container').length) {
            var index = $(this).closest('.compare-child').index();
            var url = $(this).closest('.compare-child').attr('data-update-url');
            var querystring = $(this).attr('data-url').split('?')[1];
            var defaultColor = $(this).closest('.product-tile').find('.swatch-link').eq(0).attr('href').split('?')[1];
            querystring += '&' + defaultColor;

            $(this).closest('.non-color-attribute-swatches').find('.swatch .selected').removeClass('selected');
            $(this).find('.swatch-value').addClass('selected');
            $.spinner().start();

            $.ajax({
                url: url + '?' + querystring,
                type: 'get',
                dataType: 'json',
                success: function success(data) {
                    if (data.specs && data.specs.length) {
                        $('.specs').each(function (i, spec) {
                            var specValue = data.specs[i].values[0].values;
                            if (typeof specValue != 'array')  $(spec).find('.compare-child:nth-child('+ (index + 1) +')').html(specValue)
                        });
                    }
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });

            return;
        }

        $('body').trigger('product:swatchClicked', [$(this), toggleObject]); // add trigger for any attribute click use (incl. nonClickable Attrs)
        if (scope.methods.checkForClickableAttribute($(this)) && !toggleObject.viewOutOfStockItems) {
            return;
        }

        if ($('.product-bundle').length) {
            var $productContainer = $('.bundle-main-product').length ? $(this).closest('.bundle-main-product') : $(this).closest('.product-bundle');
        } else {
            var $productContainer = $(this).closest('.set-item');
        }

        if (!$productContainer.length) {
            $productContainer = $(this).closest('.product-detail');
        }

        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);

        $(this).closest('.non-color-attribute-swatches').find('.non-color-display-value').text($(this).find('.swatch-value').data('display-value'));
    });
}

/**
 *  * SAS-Update *
 * Updating from add-to-cart click specifically for Sets - SAS-932
 * main change is "notFullSet" items
 */
base.addToCart = function () {
    var scope = this;

    // if qty stepper input has focus, we need to blur it so product data can update before button click
    $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var $button = $(event.target);
        var $container = $button.closest('.product-detail');
        if (!$container.length) {
            $container = $button.closest('.quick-view-dialog');
        }
        var $qtyStepperInput = $container.find('.quantity-stepper input');

        if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
            $qtyStepperInput.blur();
        }
    });

    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
        var addToCartUrl;
        var pid;
        var pidsObj;
        var notFullSet;
        var setPids;
        var quantity;

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
            if (!$products.length) {
                if ($(this).closest('.quick-view-dialog').length) {
                    $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                } else {
                    $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                }
            }

            //check which button the user is submitting, if its the banner bar, only grab the checked items
            notFullSet = $(this).hasClass('selected-set-items');
            $products.each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    if (!notFullSet || (notFullSet && $(this).find('.added-to-set').is(':checked'))) {
                        setPids.push({
                            pid: $(this).data().pid,
                            qty: 1,
                            options: scope.methods.getOptions($(this))
                        });
                    }
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = scope.methods.getPidValue($(this));

        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

        var $productContainer = $(this).closest('.product-detail');
        if (!$productContainer.length) {
            if (notFullSet) {
                addToCartUrl = $(this).siblings('.add-to-cart-url').val();
            } else {
                if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                    addToCartUrl = $(this).data('url');
                } else {
                    $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                    var $productModalbody = $(this).closest('.modal-content');
                    addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
                }
            }
        } else {
            addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
        }

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: scope.methods.getChildProducts(),
            quantity: quantity,
            options: scope.methods.getOptions($productContainer)
        };

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    scope.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                    if (notFullSet) $('body').trigger('productSet:clearBar', data);
                    $.spinner().stop();
                    scope.methods.miniCartReportingUrl(data.reportingURL);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}


/**
 *  * SAS-Update *
 * Updating from updateAddToCart for Sets - SAS-932
 * if a master is used and not defaulted to 'in-stock'
 * update the add to cart clickability if a variant is selected
 */
base.updateAddToCart = function () {
    $('body').on('product:updateAddToCart', function (e, response) {
        var $productContainer = response.$productContainer;

        // update the button disable-attr for sets
        if ($productContainer.hasClass('product-set-item-detail')) {
            $productContainer.find('.toggle-to-set').attr('disabled',
                (!response.product.readyToOrder || !response.product.available));

            var $setContainer = $('.product-set-detail');
            var enable = $setContainer.find('.product-availability').toArray().every(function (item) {
                return $(item).data('available') && $(item).data('ready-to-order');
            });
            $setContainer.find('button.add-to-cart-global').attr('disabled', !enable);
        }
    });

    return baseUpdateAddToCart.apply(this, arguments);
}

base.updateSetItemAttributes = function () {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        if (response.container.hasClass('product-set-item-detail')) {
            response.container.attr('data-pid', response.data.product.id);
            var elements = response.container.find('[class*="add-to-cart"] [class*="-to-set"]');
            var selectedPids = Array.from($('.set-banner-bar .slot')).map(el => $(el).data('pid').toString());
            elements.each(function() {
                $(this).attr('data-pid', response.data.product.id).prop('checked', false);
                if (selectedPids && selectedPids.includes(response.data.product.id)) {
                    $(this).prop('checked', true);
                }
            });
        }
    });
}

/**
 * Preselect Single Swatches if only one variant available and it is a swatch
   *
   * @param {object} $productContainer - DOM element holding attributes
   * @return {array} - The swatch elements that need to be selected - in this case it's just one
*/
base.preselectSingleSwatchesInContainer = function (containerSelector = 'body') {
    var swatches = [];
    if ($(containerSelector) && $(containerSelector).length > 0) {
        var attributes = $(containerSelector).find('.attribute');
        $.each(attributes, function (i) {
            var $attr = $(this),
                $nextAttr = i < (attributes.length - 1) ? $(attributes[i + 1]) : null,
                disSwatch = $attr.find('.swatch');

            if ($nextAttr?.hasClass('js-attribute-split')) {
                disSwatch = disSwatch.add($nextAttr.find('.swatch'));
            }

            if (disSwatch.length == 1) {
                var firstswatch = $(disSwatch[0]);
                // If the single swatch is already preselected do not add it to the list to be selected (i.e. going straight to a variation's pdp)
                if (!firstswatch.find('span.selectable').hasClass('selected')) {
                    swatches.push(firstswatch);
                }
                //If we want to hide the attribute selection completely when there is only one choice
                //disSwatch.parents('.attribute').addClass('visually-hidden');
            }
        });
        this.methods.selectSwatch(swatches);
    }
};

/**
 * Updating this to check for a no-update slider after attribute select (suggested-products slider on pdp)
 */
base.methods.createSlider = function (images, assets, videoAssets, $productContainer) {
    var $sliderContainers = $productContainer.find('.slider-container');
    var data = images !== null ? {images} : null;
    data.assets = assets || null;
    data.videoAssets = videoAssets || null;

    // Reversing order in which to update sliders so that thumbnails get initialized first
    $($sliderContainers.get().reverse()).each((index, sliderContainer) => {
        var $slider = $(sliderContainer).find('.slider');
        if ($slider.parents('.no-update-on-attribute-select').length) return;
        $slider.trigger('slider:update', data);
    });
}

module.exports = base;
