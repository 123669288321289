var base = require('integrations/integrations/bopis/product/pdpInstoreInventory');

/**
* Replaces the content in the modal window with find stores components and
* the result store list.
* @param {string} pid - The product ID to search for
* @param {number} quantity - Number of products to search inventory for
* @param {number} selectedPostalCode - The postal code to search for inventory
* @param {number} selectedRadius - The radius to search for inventory
*/
function fillModalElement(pid, quantity, selectedPostalCode, selectedRadius) {
   var requestData = {};
   if (pid && quantity){
       requestData.products = pid + ':' + quantity;
   };

   if (selectedRadius) {
       requestData.radius = selectedRadius;
   }

   if (selectedPostalCode) {
       requestData.postalCode = selectedPostalCode;
   }

   $('#inStoreInventoryModal').spinner().start();
   $.ajax({
       url: $('.btn-get-in-store-inventory').data('action-url'),
       data: requestData,
       method: 'GET',
       success: function (response) {
           $('#inStoreInventoryModal .modal-body').empty();
           $('#inStoreInventoryModal .modal-body').html(response.storesResultsHtml);

           $('.btn-storelocator-search').attr('data-search-pid', pid);

           if (selectedRadius) {
               $('#radius').val(selectedRadius);
           }

           if (selectedPostalCode) {
               $('#store-postal-code').val(selectedPostalCode);
           }

           var scope = $('#inStoreInventoryModal');
           var $resultsDiv = scope.find('.results');

           if (response.stores.length <= 0) {
               if ('noInventoryStores' in response) {
                   scope.find('.store-locator-no-results-for-product').show();
                   scope.find('.results').html(response.noInventoryStoresHtml);
               } else {
                   scope.find('.store-locator-no-results').show();
               }
               scope.find('.filter-results').hide();
               $resultsDiv.attr('data-has-results', false);
           } else {
               scope.find('.store-locator-no-results').hide();
               scope.find('.store-locator-no-results-for-product').hide();
               scope.find('.filter-results').show();
               $resultsDiv.attr('data-has-results', true);
           }

           $('#inStoreInventoryModal').modal('show');
           $('#inStoreInventoryModal').spinner().stop();
       },
       error: function () {
           $('#inStoreInventoryModal').spinner().stop();
       }
   });
}

base.methods.fillModalElement = fillModalElement;
module.exports = base;