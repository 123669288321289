var main = require('integrations/main');

main.baseFiles.footer = require('./components/footer');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.miniCart = require('./components/miniCart').init;
main.baseFiles.base = require('./product/base');
main.baseFiles.header = require('./components/header');
main.baseFiles.toolTip = require('./components/toolTip');
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.detail = require('./product/detail');
main.baseFiles.buttons = require('./components/buttons');
main.baseFiles.cart =  require('./cart');
main.baseFiles.scroll = require('./utilities/scroll');
main.baseFiles.headerUtils = require('./utilities/headerUtils');
main.baseFiles.pdpSummary = require('./pdpSummary');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.storeLocator = require('./storeLocator/storeLocator');
main.baseFiles.countrySelector = require('./components/countrySelector');
main.baseFiles.wishlist = require('./wishlist/wishlist').init;
main.baseFiles.tracking = require('./tracking/tracking');

var siteIntegrations = require('integrations/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
if (toggleObject.bopisCartridgeEnabled) {
    main.baseFiles.pdpInstoreInventory = require('./integrations/bopis/product/pdpInstoreInventory');
}

module.exports = main;
