'use strict';

var productBase = require('core/product/base');
var toast = require('core/components/toast');

/**
 * update clicked links attributes and text
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} $targetElement - the element that needs caused the need for a modal change
 */
function updateSaveForLaterAfterMove(e, $cardProduct) {
    if ($cardProduct) {
        var itemToRemove = {
            actionUrl: $cardProduct.find('.product-list-enhancements-card-product-remove').data().url,
            action: "moved",
            productID: $cardProduct.data().pid,
            listId: $cardProduct.data().listId,
            listItemId: $cardProduct.data().listItemId
        };

        module.exports.methods.removeProductFromSaveForLaterAJAX(e, itemToRemove);
    }
};

var eventShow = function(e) {
    $(this)
        .attr({
            'data-list-id': e.relatedTarget.data().listId,
            'data-list-item-id': e.relatedTarget.data().listItemId,
        })
        .data({
            listId: e.relatedTarget.data().listId,
            listItemId: e.relatedTarget.data().listItemId
        });
};

var eventClose = function(e) {
    $(this)
        .attr({
            'data-list-id': '',
            'data-list-item-id': ''
        })
        .data({
            listId: '',
            listItemId: ''
        });
};

var eventClickRemove = function(e) {
    e.preventDefault();
    $('#saveForLaterListModal')
        .modal('show', $(this));
};



var eventClickAddCart = function(e) {
    let $xhr;
    e.preventDefault();
    $.spinner().start();
    var $this = $(this);
    var $cardProduct = $this.parents('.save-for-later-card-product');
    var addToCartUrl = $this.data().url;
    var pid = $cardProduct.data('pid');
    var quantity = $cardProduct.find('.product-list-enhancements-card-product-quantity').val();
    var options = JSON.stringify($this.data().options);
    var cartIsEmpty = $('.cart-empty').is(':visible');

    // Cancel previous request
    $xhr && $xhr.abort && $xhr.abort();

    $xhr = $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: {
            pid,
            quantity,
            options
        },
        dataType: 'json',
        success: function (data) {
            if (data.error === false) {
                $('.product-list-enhancements-global-error-display').trigger('clear');
                productBase.methods.handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
                if (data.cartHTML) $('.cart-page').replaceWith(data.cartHTML);
                if (cartIsEmpty) $('body').trigger('saveforlater:initFromEmptyCart');
                module.exports.methods.updateSaveForLaterAfterMove(e, $cardProduct);
            } else {
                $('.product-list-enhancements-global-error-display').trigger('error');
            }
            $.spinner().stop();
        },
        error: function () {
            $('.product-list-enhancements-global-error-display').trigger('error');
            $.spinner().stop();
        }
    });
};

var eventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#saveForLaterListModal');
    var itemToRemove = {
        actionUrl: $modal.data().url,
        action: "removed",
        productID: $modal.data().pid,
        listId: $modal.data().listId,
        listItemId: $modal.data().listItemId
    };

    $.spinner().start();
    module.exports.methods.removeProductFromSaveForLaterAJAX(e, itemToRemove);

    $('body').on('saveforlater:afterRemoval', function () {
        $modal.modal('hide');
        $.spinner().stop();
    });
}

var removeProductFromSaveForLaterAJAX = function(e, itemToRemove) {
    e.preventDefault();
    $.ajax({
        url: itemToRemove.actionUrl,
        method: 'POST',
        dataType: 'json',
        data: {
            listId: itemToRemove.listId,
            listItemId: itemToRemove.listItemId,
            action: itemToRemove.action
        },
        success: function(data) {
            $('.product-list-enhancements-detail-save-for-later').replaceWith(data.saveForLaterHTML);
            toast.methods.show('success', data.message, null);
            $('body').trigger('saveforlater:afterRemoval', data);
        },
        error: function() {
            var errorMsg = $('.product-list-enhancements-detail-save-for-later').data().errorMsg;
            toast.methods.show('warning', errorMsg, null);
            $('body').trigger('saveforlater:afterRemoval');
        }
    });
}

var addEventListeners = function() {
    $('#saveForLaterListModal')
        .on('click', '.product-list-enhancements-modal-delete-product-btn', eventClickDelete)
        .on('show.bs.modal', eventShow)
        .on('hidden.bs.modal', eventClose)

    $('.save-for-later-card-product')
        .on('click', '.product-list-enhancements-card-product-remove', eventClickRemove)
        .on('click', '.product-list-enhancements-card-product-add', eventClickAddCart);

    $('body')
        .on('saveforlater:afterHTMLswap saveforlater:afterRemoval saveforlater:afterAddToCart', init)
        .on('saveforlater:afterRemoval', function (e, data) {
            $('.save-for-later-button[data-wishlistpid="'+data.removedItemPID+'"]').removeClass('d-none').show();
        });
};

var removeEventListeners = function() {
    $('#saveForLaterListModal')
        .off('click', '.product-list-enhancements-modal-delete-product-btn', eventClickDelete)
        .off('show.bs.modal', eventShow)
        .off('hidden.bs.modal', eventClose)

    $('.save-for-later-card-product')
        .off('click', '.product-list-enhancements-card-product-remove', eventClickRemove)
        .off('click', '.product-list-enhancements-card-product-add', eventClickAddCart);

    $('body').off('saveforlater:afterHTMLswap saveforlater:afterRemoval saveforlater:afterAddToCart', init);
};

var init = function() {
    module.exports.destroy();
    module.exports.methods.addEventListeners();
}

var destroy = function() {
    module.exports.methods.removeEventListeners();
}

module.exports = {
    methods: {
        updateSaveForLaterAfterMove,
        eventClickAddCart,
        eventClickDelete,
        removeProductFromSaveForLaterAJAX,
        addEventListeners,
        removeEventListeners,
        eventShow,
        eventClose,
        eventClickRemove
    },
    init,
    destroy
}
