


'use strict';

var base = require('core/product/detail');
var photoSwipe = require('../photoswipe/photoswipe-functions');
var scrollAnimate = require('core/components/scrollAnimate');

// Modification from base to scope updateAddAllToCart to event container
base.updateAttributes = function() {
    $('body').on('product:statusUpdate', function (e, data) {
        var $productContainer = $('.product-detail[data-pid="' + data.id + '"]');

        $productContainer.find('.main-content-group .product-attributes')
            .empty()
            .html(data.attributesHtml);


        $('body').trigger('tooltip:init');
        if ($('button.add-to-cart-global').length) {
            if (!$productContainer.length) {
                $productContainer = $('#' + data.id).closest('.product-detail');
            }
            if ($productContainer.length) {
                module.exports.updateAddAllToCart($productContainer);
            }
        }
    });
}

base.showBVReviews = function () {
    //fix for bv show reviews button if the collapsible content is still closed
    $('body').on('click', '.bv_button_buttonFull', function(){
        var $this = $('.bv_button_buttonFull');
        var $reviews = $('[data-bv-show="reviews"]');
        var $reviewContainer = $reviews.closest('.collapsible-content-group');
        var callTimeOut = () => setTimeout(() => $this.click(), 50);
        if (!$reviewContainer.hasClass('active')) {
            $reviewContainer.addClass('active');
            callTimeOut();
        } else if ($reviewContainer.hasClass('active') && !$('.reviews-tab').hasClass('active')) {
            $('.bv-review-container').find('.active').removeClass('active');
            $('.reviews-tab').addClass('active');
            callTimeOut();
        }
    });
}

base.resetMainAttributeAppearance = function () {
    $('body').on('product:afterAttributeSelect', function () {
        $('body').trigger('tooltip:init');
        base.setCollapsibleContentState();
    });
}

base.reInitLightBox = function () {
    var isImageGridOn = $('.image-grid-outer .image-grid-inner').length > 0;
    var eventsToListen = isImageGridOn ? 'product:afterAttributeSelect' : 'product:afterAttributeSelect quickview:ready';

    $('body').on(eventsToListen, function () {
        photoSwipe.initPhotoSwipeCarousel('.primary-images-main');
        if ($('.primary-images-thumbnails .video-asset-links').length) {
            photoSwipe.initPhotoSwipeCarousel('.primary-images-thumbnails .video-asset-links');
        }
    });
}

base.learnMoreAnchor = function() {
    $('body').on('click', '.learn-more-anchor', function () {
        var $target = $('#'+$(this).attr('aria-controls')).closest('.collapsible-content-group');
        if (!$target.hasClass('active')) $target.addClass('active');
        scrollAnimate($target);
    });
}

// Modification from base to scope the buttons to event container
base.updateAddAllToCart = function ($productContainer) {
    // if default variants are selected, enable the add all to cart btn on load
    var $productContainer = $($productContainer);
    if ($productContainer.find('button.add-to-cart-global').length) {
        var enable = $productContainer.find('.product-availability').toArray().every(function (item) {
            return $(item).data('available') && $(item).data('ready-to-order');
        });
        $productContainer.find('button.add-to-cart-global').attr('disabled', !enable);
    }
    return false;
}

base.popoverFocusHelper = function () {
    $('body').on('show.bs.popover', '[data-toggle="popover"]', function (e) {
        var $this = $(this);
        var $dialog = e && e.currentTarget ? $(e.currentTarget) : $('body');

        $dialog.on('hide.bs.popover', () => { $this.trigger('focus') });
    })
};

base.triggerImageCarouselModal = photoSwipe.initPhotoSwipeCarousel('.primary-images-main');
base.triggerVideoCarouselModal = photoSwipe.initPhotoSwipeCarousel('.video-asset-links');

module.exports = base;
