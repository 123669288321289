'use strict';

var cart = require('core/cart/cart');
var minicart = require('../components/miniCart');
var promocode = require('../utilities/promocode');

cart.updateShippingCostData = function () {
    $('body')
        .on('cart:shippingMethodSelected', function (e, data) {
            var $cart = $('.cart');
            $cart.find('.shipping-cost').attr('data-cost', data.totals.totalShippingCost);
    })
        .on('cart:update', function (e,data) {
            var $cartpage = $('.cart-page');
            var titleWithCount;
            var $cart = $('.cart');
            if (data.totals && data.totals.totalShippingCost) {
                $cart.find('.shipping-cost').attr('data-cost', data.totals.totalShippingCost);
            } else {
                $cart.find('.shipping-cost').attr('data-cost', data.basket.totals.totalShippingCost);
            }
            if ('resources' in data) titleWithCount = data.resources.titleWithCount;
            if ('cartModel' in data && data.cartModel.resources) titleWithCount = data.cartModel.resources.titleWithCount;
            if ('basket' in data && data.basket.resources) titleWithCount = data.basket.resources.titleWithCount;
            if (titleWithCount) {
                $cartpage.find('.page-title').empty().html(titleWithCount);
            }
    })
}

cart.changeDeleteProductAjaxHandling = function () {
    $('body')
        .off('click', '.cart-delete-confirmation-btn')
        .on('click', '.cart-delete-confirmation-btn', function (e) {
            e.preventDefault();

            var productID = $(this).data('pid');
            var url = $(this).data('action');
            var uuid = $(this).data('uuid');
            var urlParams = {
                pid: productID,
                uuid: uuid
            };

            url = module.exports.appendToUrl(url, urlParams);

            $('body > .modal-backdrop').remove();

            $('body').trigger('cart:beforeUpdate');

            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    //SAS CHANGE: removing the injected emptyCartMsg and just un-hiding 'cart-empty' div
                    if (data.basket.items.length === 0) {
                        $('.cart-page').children(':not(.product-list-enhancements-detail-save-for-later)').hide();
                        $('.cart-page').find('.cart-empty').show().addClass('d-flex');
                        $('.minicart-quantity').empty().append(data.basket.numItems);
                        $('.minicart-link').attr({
                            'aria-label': data.basket.resources.minicartCountOfItems,
                            title: data.basket.resources.minicartCountOfItems
                        });
                        $('.minicart .popover').empty().removeClass('show');
                        $('body').removeClass('modal-open');
                        $('html').removeClass('veiled');
                    } else {
                        if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                            for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                                $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                            }
                        }
                        $('.uuid-' + uuid).closest('.card').remove();
                        if (!data.basket.hasBonusProduct) {
                            $('.bonus-product').remove();
                        }
                        // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                        $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                        module.exports.updateCartTotals(data.basket);
                        module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                        $('body').trigger('setShippingMethodSelection', data.basket);
                        module.exports.validateBasket(data.basket);
                    }

                    $('body').trigger('cart:update', [data, uuid]);
                    minicart.methods.hideMiniCartBackdrop();

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        });
}

cart.init = (function(init) {
    function extendsInit() {
        init();
        cart.updateShippingCostData();
        cart.changeDeleteProductAjaxHandling();
        promocode.applyHeaderBannerPromo();
    }

    return extendsInit;
})(cart.init);

module.exports = cart;

